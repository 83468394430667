@keyframes scaleAnimation {
  0% {
    /* Initial state */
    transform: scale(1);
  }
  50% {
    /* Final state */
    transform: scale(1.2);
  }
  100% {
    /* Initial state */
    transform: scale(1);
  }
}

@keyframes colorAnimation {
  100%,
  0% {
    color: #d4af37;
  }
  8% {
    color: #d7b547;
  }
  16% {
    color: #dbbc57;
  }
  25% {
    color: #dec267;
  }
  33% {
    color: #e2c978;
  }
  41% {
    color: #e5cf88;
  }
  50% {
    color: #e9d698;
  }
  58% {
    color: #ecdca8;
  }
  66% {
    color: #f0e3b8;
  }
  75% {
    color: #f3e9c8;
  }
  83% {
    color: #f7f0d8;
  }
  91% {
    color: #faf6e9;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.animore-logo {
  margin: 20px;
  cursor: pointer;
}

.menu-icon {
  color: white;
  margin-left: 10px;
}

.MuiButtonBase-root.MuiTab-root {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;

  color: #ede5d8;
}
.MuiButtonBase-root.MuiTab-root.Mui-selected {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;

  color: #ede5d8;
  background: rgba(189, 169, 140, 0.15);
}
