.intro-block {
  padding: 40px 0 60px;
  display: inline-grid;
}

.lucerna-uni {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 18px;

  color: #f8cf49;
}

.winning-header {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 60px;
  text-align: center;
  margin-top: 5px;

  color: #ffffff;
}

.find-out-sub {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 30px;

  color: #ffffff;
}

.major-card-div {
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  grid-template-rows: repeat(2, 400px);
  gap: 20px;
  grid-auto-flow: column;
  flex-wrap: wrap;
}

.how-to-join-div {
  margin-top: 50px;
  background: rgba(237, 229, 216, 0.85);
  display: flex;
  flex-direction: column;
  padding: 40px 20vw;
}

.join-header {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 60px;
  text-align: center;
}

.join-text {
  margin: 20px 0 0 0;
  line-height: 28px;
  font-family: "Arvo";
  font-size: 18px;
}

.MuiButton-root.join-btn {
  width: fit-content;
  margin-top: 20px;
  align-self: center;
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  color: #f8cf49;
  background-color: black;
}

.join-btn:hover {
  background-color: grey;
  color: black;
}
