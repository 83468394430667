.game-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.MuiCard-root.game-card {
  max-width: 500px;
  min-width: 500px;
  margin: 20px;
  background-color: rgba(237, 229, 216, 0.15);
  border: 1.97656px solid rgba(189, 169, 140, 0.25);
  box-shadow: 0px 4px 8px rgba(17, 17, 17, 0.35);
  border-radius: 8px;
  flex: 1 0 20%;
  margin: 5px;
}

.MuiCard-root.game-card-mobile {
  width: 100%;
  min-width: 90%;
  margin: 20px 10px 0;
}

.MuiCardContent-root {
  min-height: 100%;
}

.game-card-content {
  flex-direction: column;
  display: flex;
  margin: 20px;
}

.major-badge-img:hover {
  animation: scaleAnimation 0.5s ease-in-out;
}

.game-img {
  border-radius: 8px;
}

.game-card-name {
  display: block;
  margin-bottom: 20px;
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;

  text-transform: uppercase;

  color: #fefaf3;
}

.game-card-description {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #bda98c;
  margin-top: 20px;
}

.MuiButton-root.game-card-button {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;

  color: #f8cf49;
}

.MuiCardActions-root {
  justify-content: flex-end;
}
