.MuiCard-root.major-card {
  color: pink;
  max-width: 500px;
  min-width: 500px;
  margin: 0px;
  background-color: rgba(237, 229, 216, 0.15);
  border: 1.97656px solid rgba(189, 169, 140, 0.25);
  box-shadow: 0px 4px 8px rgba(17, 17, 17, 0.35);
  border-radius: 8px;
  /* max-height: 312px; */
}

.MuiCardContent-root {
  min-height: 300px;
}

.MuiCardContent-root.card-content {
  padding: 0 0 0 16px;
}

.card-content {
  flex-direction: row;
  display: flex;
}

.card-first-half {
  width: 40%;
  margin: auto;
}

.card-second-half {
  width: 40%;
}

.char-img {
  width: 350px;
  margin-left: -100px;
}

.major-badge-img:hover {
  animation: scaleAnimation 0.5s ease-in-out;
}

.card-name {
  display: block;
  margin-bottom: 20px;
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;

  text-transform: uppercase;

  color: #fefaf3;
}

.card-description {
  width: 200px;
  height: 96px;

  font-family: "Arvo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  color: #bda98c;
}

.MuiButton-root.card-button {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;

  color: #f8cf49;
}

.MuiCardActions-root {
  justify-content: flex-end;
}

/* Details Modal */

.modal {
  background: #ede5d8;
  border: 1.97656px solid rgba(189, 169, 140, 0.25);
  box-shadow: 0px 4px 8px rgba(17, 17, 17, 0.35);
  border-radius: 8px;
  overflow: hidden;
  padding: 4px;
  width: 638px;
  height: 550px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}

.text-content {
  display: inline-grid;
  margin: 40px 0 0 40px;
  min-width: 40%;
  height: 100%;
}

.modal-char-img {
  height: 570px;
  margin-left: -100px;
}

.modal-major {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  color: #4f2f16;
  margin-bottom: 10px;
}

.modal-name {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  text-transform: uppercase;

  color: #4f2f16;
  margin-bottom: 30px;
}

.modal-subheader {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  color: #4f2f16;
}

.modal-subtext {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #4f2f16;
  margin-bottom: 20px;
}
