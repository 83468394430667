.footer-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 60px;
  gap: 12px;

  height: 283px;

  background: #111111;
  width: 100%;
  margin-top: 100px;
}

.footer-row {
  display: flex;
}

.social-btn {
  margin-right: 10px;
  cursor: pointer;
}

.credit-div {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-left: 20px;

  color: #bda98c;
}

.copyright {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;

  color: #bda98c;
  margin-left: 20px;
}
