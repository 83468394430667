.leaderboard-header {
  max-width: 100vw;
  margin-bottom: -70px;
}

.leaderboard-header-mobile {
  max-width: 90vw;
  height: 100%;
  margin: 20px auto;
  display: flex;
}

.leaderboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 64px 64px;
  gap: 16px;

  width: 100vw;
  height: 1381px;

  background: rgba(237, 229, 216, 0.95);
  border-top: 3px solid #a28a6d;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);

  margin: auto;
  height: 100%;
}

.leaders-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.leader1-card {
  width: 304px;
  height: 290px;
  background: #fefaf3;
  border: 1px solid #a28a6d;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: inline-grid;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.leader23-card {
  width: 302px;
  height: 257px;

  background: #fefaf3;
  border: 1px solid #a28a6d;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: inline-grid;
  justify-content: center;
  z-index: 0;
  position: relative;
}

.leader2-card {
  margin-right: -40px;
}

.leader3-card {
  margin-left: -40px;
}

.leader-img {
  margin: 10px auto 0;
}

.leader-ribbon {
  margin: auto;
}

.leader-ribbon:hover {
  animation: scaleAnimation 0.5s ease-in-out;
}

.players-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 16px;
  margin-bottom: 15px;

  width: 475px;
  height: 72px;

  background: #fefaf3;
  border: 1px solid #a28a6d;
  border-radius: 4px;
}

.player-card-mobile {
  width: 80vw;
}

.player-card-mobile .player-name {
  line-height: 24px;
}

.player-card-mobile .points-div {
  max-width: 50px;
  padding: 0 8px;
  gap: 5px;
  min-width: fit-content;
}

.player-position {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;

  text-align: center;

  color: #1c1916;
}

.player-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.player-img:before {
  content: " ";
  display: block;
  position: absolute;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-image: url("/public/users/default.png");
}

.player-name {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  max-width: 150px;
  color: #1c1916;
}

.points-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 10px;

  min-width: 100px;
  max-width: 100px;
  height: 48px;

  background: rgba(237, 229, 216, 0.85);
  border: 1px solid #a28a6d;
  border-radius: 4px;
}

.tier-img:hover {
  animation: scaleAnimation 0.5s ease-in-out;
}

.points {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 48px;

  color: #1c1916;
}
