.loading-buffer {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small {
  min-height: 100%;
}
