.progress-div {
  font-family: "Arvo";
  font-style: normal;
  background-color: #ede5d8;
  border-radius: 5px;
  padding: 20px 20px 30px;
  margin: 20px 10vw;
  font-size: 1.2em;
}

.MuiLinearProgress-root.MuiLinearProgress-root.progress-line {
  border-radius: 10px;
  margin: 10px 0;
  min-height: 8px;
  background-color: lightgrey;
}

.MuiLinearProgress-bar {
  border-radius: 10px;
}

.progress-line-0 {
  animation: colorAnimation 1s ease-in-out;
  color: #ffd700;
}

.MuiLinearProgress-root.progress-line-1 {
  color: #c0c0c0;
}

.MuiLinearProgress-root.progress-line-2 {
  color: #cd7f32;
}

.MuiLinearProgress-root.progress-line-3 {
  color: #b5a642;
}

.major-tab-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.major-tab-row-mobile {
  flex-wrap: wrap;
}

.major-tab {
  padding: 15px;
  width: 170px;
  margin: 0 10px;
  height: 65px;

  background: rgba(254, 250, 243, 0.15);
  border: 1px solid #a28a6d;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  position: relative;
}

.major-tab-selected {
  background: #ede5d8;
}

.major-tab-mobile {
  width: 50px;
  margin: 0 2px;
}

.cp-ribbon {
  max-width: 40px;
  position: absolute;
  top: -10px;
  left: -15px;
}

.cp-ribbon:hover {
  animation: scaleAnimation 0.5s ease-in-out;
}

.cp-ribbon-first {
  right: 10px;
}

.major-tab-badge {
  max-width: 55px;
  float: left;
}

.major-tab-info {
  display: inline-grid;
  justify-items: baseline;
  color: #fefaf3;
}

.major-tab-info-selected {
  color: black;
}

.major-tab-first {
  margin-left: -41px;
}

.major-tab-name {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  text-transform: uppercase;
}

.major-tab-xp {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.uni-div {
  background: #ede5d8;
  padding: 40px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  min-height: 70vh;
}

.uni-div-mobile {
  width: auto;
  border-radius: 4px;
  display: block;
}

.uni-div-info-box-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.uni-div-info-mobile {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.uni-img {
  width: 600px;
  border-radius: 4px;
  height: auto;
  margin: auto;
}

.uni-img-mobile {
  width: 80vw;
  height: auto;
}

.stats-box {
  font-family: "Arvo";
  font-style: normal;
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  line-height: 30px;
  font-size: 1.2em;
  justify-content: center;
  text-align: left;
}

.stats-box-mobile {
  margin: 20px 0;
}

.stat-icon {
  vertical-align: text-bottom;
  margin-right: 5px;
}

.recent-events {
  font-size: 0.9em;
  margin: 10px 0 0;
}
