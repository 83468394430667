.recent-activity-div {
  margin: 0 100px;
}

.recent-activity-div-mobile {
  margin: 0 10vw;
}

.recent-activity-header {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;

  color: #f8cf49;
  display: flex;

  margin-bottom: 20px;
}

.event-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 12px;

  width: 80vw;

  background: #ede5d8;
  border: 1px solid #a28a6d;
  border-radius: 4px;
  margin: 0 auto 20px;
}

.event-card-mobile {
  width: 100%;
}

.event-title {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;

  color: #1c1916;
}

.event-text {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 50vw;
  color: #1c1916;
}

.event-user {
  font-weight: 900;
}

.event-date {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #333130;
}
